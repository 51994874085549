import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { useLocation } from 'react-router-dom'; // Importer useLocation pour obtenir le chemin actuel
import '../App.css';

const Navigation = () => {
  const location = useLocation(); // Obtenir le chemin actuel
  
  return (
    <Navbar expand="lg" bg="" className="navigation">
      <Navbar.Toggle aria-controls="navbar-nav" />
      <Navbar.Collapse id="navbar-nav">
        <Nav className="ms-auto">
          <Nav.Link
            href="/"
            className={location.pathname === '/' ? 'active-link' : ''}
            style={{
              fontWeight: 'bold',
              textDecoration: 'underline',
              fontFamily: 'Arial, sans-serif',
              fontSize: '18px',
              textTransform: 'uppercase',
              letterSpacing: '1px'
            }}
          >
            Home
          </Nav.Link>
          <Nav.Link
            href="/ziel"
            className={location.pathname === '/ziel' ? 'active-link' : ''}
            style={{
              fontWeight: 'bold',
              textDecoration: 'underline',
              fontFamily: 'Arial, sans-serif',
              fontSize: '18px',
              textTransform: 'uppercase',
              letterSpacing: '1px'
            }}
          >
            Unsere Projekte
          </Nav.Link>
          <Nav.Link
            href="/geschickte"
            className={location.pathname === '/geschickte' ? 'active-link' : ''}
            style={{
              fontWeight: 'bold',
              textDecoration: 'underline',
              fontFamily: 'Arial, sans-serif',
              fontSize: '18px',
              textTransform: 'uppercase',
              letterSpacing: '1px'
            }}
          >
            Unsere Geschichte
          </Nav.Link>
          <Nav.Link
            href="/spenden"
            className={location.pathname === '/spenden' ? 'active-link' : ''}
            style={{
              fontWeight: 'bold',
              textDecoration: 'underline',
              fontFamily: 'Arial, sans-serif',
              fontSize: '18px',
              textTransform: 'uppercase',
              letterSpacing: '1px'
            }}
          >
            Spenden
          </Nav.Link>
          <Nav.Link
            href="/ueber-uns"
            className={location.pathname === '/ueber-uns' ? 'active-link' : ''}
            style={{
              fontWeight: 'bold',
              textDecoration: 'underline',
              fontFamily: 'Arial, sans-serif',
              fontSize: '18px',
              textTransform: 'uppercase',
              letterSpacing: '1px'
            }}
          >
            Über uns
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;
