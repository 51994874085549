import React from 'react';
import Header from '../Components/Header';
import Navigation from '../Components/Navigation';
import Footer from '../Components/Footer';
import { Container, Row, Col, Jumbotron, Button } from 'react-bootstrap';

const Geschickte = () => {
    return (
        <div>
           <div className='header'><Header/></div>
<div><Navigation/></div>
      <div className="bg-light text-center py-5">
        <Container>
          <h2>Unsere Geschichte</h2>
                 <p>
                 Das Geschichte wird hier erzählt .............
                  </p>  
        </Container>
      </div>
      <div><Footer/></div>
        </div>
    );
};

export default Geschickte;