import React from 'react';
import Header from '../Components/Header';
import Navigation from '../Components/Navigation';
import Footer from '../Components/Footer';
import { Container } from 'react-bootstrap';

const UeberUns = () => {
    return (
        <div>
            <div className='header'><Header /></div>
            <div><Navigation /></div>

            {/* Wer sind wir? */}
            <div className="bg-light text-center py-5">
                <Container>
                    <h2>Wer sind wir?</h2>
                    <p>Ein christlicher Verein mit dem Ziel, den Glauben an Jesus Christus zu fördern.</p>
                    <p><strong>Jesus spricht:</strong> "Ich bin der Weg und die Wahrheit und das Leben; niemand kommt zum Vater denn durch mich." (Johannes 14:6, Lutherbibel 2017)</p>
                    <p><strong>Mission und Vision:</strong> Förderung des christlichen Glaubens und der sozialen Integration.</p>
                    <p><strong>Unsere Geschichte:</strong> Gegründet im Jahr 2014, um den christlichen Glauben in unserer Gemeinde zu stärken und soziale Projekte zu unterstützen.</p>
                </Container>
            </div>
            {/* Werte und Ethik */}
            <div className="bg-light text-center py-5">
                <Container>
                    <h2>Unsere Werte</h2>
                    <p>Wir legen großen Wert auf Integrität, Transparenz und soziale Verantwortung. Unsere Arbeit basiert auf den Prinzipien des christlichen Glaubens und des respektvollen Umgangs miteinander.</p>
                </Container>
            </div>

            {/* Erfolge und Meilensteine */}
            <div className="bg-light text-center py-5">
                <Container>
                    <h2>Erfolge und Meilensteine</h2>
                    <p>Seit unserer Gründung haben wir zahlreiche Projekte erfolgreich umgesetzt und viele Menschen unterstützt. Hier sind einige unserer wichtigsten Erfolge:</p>
                    <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                        <li>Hilfe für Gefangene</li>
                        <li>Hilfe für Waisenkinder</li>
                        {/* Füge hier weitere Erfolge hinzu */}
                    </ul>
                </Container>
            </div>

            {/* Zukunftspläne */}
            <div className="bg-light text-center py-5">
                <Container>
                    <h2>Unsere Zukunft</h2>
                    <p>Wir planen, unsere Aktivitäten weiter auszubauen und neue Projekte zu initiieren, um noch mehr Menschen zu erreichen und zu unterstützen. Unsere zukünftigen Ziele umfassen:</p>
                    <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                        <li>Evangelisationskampagnen durchführen</li>
                        <li>Waisenhäuser eröffnen und Waisenkinder unterstützen</li>
                        <li>Gefangene und Bedürftige helfen</li>
                        {/* Füge hier weitere Zukunftspläne hinzu */}
                    </ul>
                </Container>
            </div>

            <div><Footer /></div>
        </div>
    );
};

export default UeberUns;
