import React from 'react';
import Header from '../Components/Header';
import Navigation from '../Components/Navigation';
import Footer from '../Components/Footer';
import { Container } from 'react-bootstrap';

const Datenschutz = () => {
    const listStyle = {
        listStyleType: 'none',
        paddingLeft: '0',
    };

    return (
        <div>
            <div className='header'><Header /></div>
            <div><Navigation /></div>
            <div className="bg-light text-center py-5">
                <Container>
                    <h2>Datenschutzerklärung</h2>
                    <p>
                        Der Schutz Ihrer persönlichen Daten ist uns ein wichtiges Anliegen. Diese Datenschutzerklärung informiert Sie darüber, wie wir personenbezogene Daten auf unserer Website erheben, verwenden und schützen. Sie gilt für alle Besucher und Nutzer unserer Website.
                    </p>
                    <h3>1. Verantwortliche Stelle</h3>
                    <p>
                        Verantwortlich für die Verarbeitung Ihrer personenbezogenen Daten ist:
                    </p>
                    <p>
                        <strong>Das Evangelium e.v</strong><br />
                        Ein christlicher Verein mit dem Ziel, den Glauben an Jesus Christus zu fördern.<br />
                        Kontakt: dasevangeliume.v@gmail.com
                    </p>
                    <h3>2. Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck der Verwendung</h3>
                    <p>
                        Wir erheben und verarbeiten personenbezogene Daten, wenn Sie uns diese freiwillig zur Verfügung stellen, z. B. durch das Ausfüllen eines Kontaktformulars, im Rahmen von Spenden oder bei der Anmeldung für unsere Veranstaltungen.
                    </p>
                    <p><strong>Folgende Daten werden erhoben:</strong></p>
                    <ul style={listStyle}>
                        <li>Name und Vorname</li>
                        <li>E-Mail-Adresse</li>
                        <li>Weitere Kontaktdaten, wenn diese im Rahmen einer Spende oder Veranstaltung angegeben werden.</li>
                    </ul>
                    <p><strong>Zweck der Datenerhebung:</strong></p>
                    <ul style={listStyle}>
                        <li>Kontaktaufnahme und Kommunikation</li>
                        <li>Bearbeitung von Spenden</li>
                        <li>Anmeldung und Organisation von Veranstaltungen</li>
                        <li>Erfüllung unserer Vereinsziele, insbesondere der Förderung des christlichen Glaubens und der sozialen Integration</li>
                    </ul>
                    <h3>3. Datenverarbeitung bei Spenden</h3>
                    <p>
                        Wenn Sie über unsere Website spenden möchten, erheben wir die notwendigen Daten, um die Spende abzuwickeln. Die Daten werden streng vertraulich behandelt und nicht an Dritte weitergegeben, es sei denn, dies ist zur Abwicklung der Spende notwendig (z. B. an Zahlungsdienstleister).
                    </p>
                    <h3>4. Weitergabe von Daten</h3>
                    <p>
                        Eine Weitergabe Ihrer Daten an Dritte erfolgt nur, wenn dies für die Erfüllung unserer Vereinsziele erforderlich ist oder wir gesetzlich dazu verpflichtet sind. Eine Übermittlung Ihrer Daten in Drittstaaten findet nicht statt.
                    </p>
                    <h3>5. Datensicherheit</h3>
                    <p>
                        Wir setzen technische und organisatorische Sicherheitsmaßnahmen ein, um Ihre personenbezogenen Daten gegen unberechtigten Zugriff, Verlust oder Zerstörung zu schützen.
                    </p>
                    <h3>6. Rechte der betroffenen Personen</h3>
                    <p>
                        Sie haben das Recht, jederzeit Auskunft über die von uns gespeicherten personenbezogenen Daten zu verlangen. Zudem können Sie die Berichtigung, Löschung oder Einschränkung der Verarbeitung Ihrer Daten verlangen.
                    </p>
                    <p><strong>Ihre Rechte umfassen:</strong></p>
                    <ul style={listStyle}>
                        <li>Recht auf Auskunft</li>
                        <li>Recht auf Berichtigung</li>
                        <li>Recht auf Löschung ("Recht auf Vergessenwerden")</li>
                        <li>Recht auf Einschränkung der Verarbeitung</li>
                        <li>Recht auf Widerspruch gegen die Verarbeitung</li>
                        <li>Recht auf Datenübertragbarkeit</li>
                    </ul>
                    <p>
                        Bei Anliegen in Bezug auf Ihre personenbezogenen Daten können Sie sich jederzeit an uns wenden.
                    </p>
                    <h3>7. Dauer der Datenspeicherung</h3>
                    <p>
                        Wir speichern personenbezogene Daten nur so lange, wie dies für die Erreichung der oben genannten Zwecke erforderlich ist oder wie es gesetzliche Aufbewahrungspflichten vorsehen.
                    </p>
                    <h3>8. Änderungen der Datenschutzerklärung</h3>
                    <p>
                        Wir behalten uns das Recht vor, diese Datenschutzerklärung bei Bedarf anzupassen. Über wesentliche Änderungen informieren wir Sie rechtzeitig auf unserer Website.
                    </p>
                </Container>
            </div>
            <div><Footer /></div>
        </div>
    );
};

export default Datenschutz;
