import React from 'react';
import Header from '../Components/Header';
import Navigation from '../Components/Navigation';
import Footer from '../Components/Footer';
import { Container, Row, Col, Jumbotron, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Home = () => {
    return (
        <div>
          <div className='header'>
          <Header/>         
      </div>
<div>
  <Navigation/>
</div>
<Container className="py-5">
  <div className="bg-light text-center py-5">
    <h2>Wer sind wir?</h2>
    <ul>Ein christlicher Verein mit dem Ziel, den Glauben an Jesus Christus zu fördern.</ul>
    <ul>Jesus spricht: <strong>"Ich bin der Weg und die Wahrheit und das Leben; niemand kommt zum Vater außer durch mich."</strong> Johannes 14:6 (Lutherbibel 2017)</ul>
    <ul><strong>Mission und Vision:</strong> Förderung des christlichen Glaubens und der sozialen Integration.</ul>
  </div>
</Container>

<div className="bg-light text-center py-5">
  <Container>
    <h2>Unsere Ziel</h2>
    <ul>Förderung des Evangeliums von Jesus Christus</ul>
    <ul>Unterstützung der Gemeinschaft und bedürftiger Menschen</ul>
    <ul>Förderung von Bildung und Erziehung im christlichen Glauben</ul>
    <ul>Anerkennung und Förderung von Vielfalt und Integration</ul>
    <ul>Engagement in der Jugend- und Altenhilfe</ul>
    <p>
                 Unser Ziel ist es, die gute Nachricht des Heils in Jesus Christus zu verkünden, gemäß dem Auftrag in Markus 16,15:
                  'Geht hin in die ganze Welt und predigt das Evangelium der ganzen Schöpfung.' 
                 Und danach Jünger Jesu zu machen, wie es in Matthäus 28,19 heißt: 'Geht hin und macht zu Jüngern alle Völker.'
                  </p>   
  </Container>
</div>
<div className="bg-light text-center py-5">
  <Container>
    <h2>Unsere Projekte</h2>
    <p> <strong>Projekt1: "Resiliente Gesellschaft: Achtsamkeit und Stärke im Alltag"</strong></p>
    <p> <strong>Projektbeschreibung:</strong></p>
<p>Stress im Alltag ist ein wachsendes Problem in unserer Gesellschaft und wirkt sich sowohl auf die psychische als auch
 auf die körperliche Gesundheit vieler Menschen aus. </p> <p>
Unser Verein Das Evangelium e.V. möchte mit dem Projekt "Resiliente Gesellschaft:
 Achtsamkeit und Stärke im Alltag" Menschen dabei unterstützen, den negativen Stress 
 frühzeitig zu erkennen und geeignete Gegenmaßnahmen zu ergreifen.</p> 
 <Link to="/ziel">Mehr dazu</Link>
  </Container>
</div>

<div className="bg-light text-center py-5">
  <Container>
    <h2>Unsere Arbeit</h2>
    <ul>Veranstaltungen: Gottesdienste, Workshops, Schulungen</ul>
    <ul>Soziale Projekte: Unterstützung von Hilfsbedürftigen, Bildungsarbeit</ul>
    <ul>Missionarische Arbeit: Projekte im In- und Ausland</ul>
  </Container>
</div>

<div className="bg-light text-center py-5">
  <Container>
    <h2>Spenden</h2>
    <p>Vielen Dank für Ihr Interesse, uns zu unterstützen. Falls es sich um eine Spende oder den Zehnten handelt, bitte als Verwendungszweck angeben. Hier finden Sie die Informationen, wie Sie spenden können:</p>

    <Row className="my-4">
      <Col md={6} className="text-md-end mb-3 mb-md-0">
        <h5>Spenden über PayPal</h5>
        <p>
          Nutzen Sie diesen Link, um direkt über PayPal zu spenden:<br />
          <a href="https://paypal.me/rencontrepentecote?country.x=DE&locale.x=de_DE" target="_blank" rel="noopener noreferrer">Spenden über PayPal</a>
        </p>
      </Col>
      <Col md={6} className="text-md-start">
        <h5>Banküberweisung</h5>
        <p>
          Hier sind unsere Bankdaten für Überweisungen:<br />
          Bankname: kommt noch<br />
          IBAN: kommt noch<br />
          BIC: kommt noch<br />
          Verwendungszweck: Spende oder Zehnten
        </p>
      </Col>
    </Row>

    <p>Für weitere Fragen oder Unterstützung, kontaktieren Sie uns bitte unter <a href="mailto:dasevangeliume.v@gmail.com">dasevangeliume.v@gmail.com</a>.</p>
  </Container>
      </div>
      <div><Footer/></div>
    </div>       
    );
};

export default Home;