import React from 'react';
import { Navbar } from 'react-bootstrap';
import '../App.css';

const Header = () => {
  return (
    <div>
      <Navbar expand="lg">
        <div className="container-fluid">
          <Navbar.Brand href="#" className="text-left">
            <img 
              src="/img/logo.png"
              width="200"
              height="200"
              className="logo"
              alt="Logo"
            />
            {/* Texte sous le logo */}
            <div className="text-below-logo">
              <h4>Das Evangelium e.V.</h4>
              <h4>Gemeinsam im Glauben, stark im Handeln</h4>
            </div>
          </Navbar.Brand>
        </div>
      </Navbar>
    </div>
  );
};

export default Header;
