import React from 'react';
import Header from '../Components/Header';
import Navigation from '../Components/Navigation';
import Footer from '../Components/Footer';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

const Kontakt = () => {
  const handleSubmit = (e) => {
    e.preventDefault();

    const name = e.target.name.value;
    const email = e.target.email.value;
    const message = e.target.message.value;

    const mailtoLink = `dasevangeliume.v@gmail.com?subject=Contact%20Form%20Submission&body=Name:%20${encodeURIComponent(name)}%0D%0AEmail:%20${encodeURIComponent(email)}%0D%0AMessage:%20${encodeURIComponent(message)}`;

    window.location.href = mailtoLink;
  };

  return (
    <div>
      <div className='header'><Header /></div>
      <div><Navigation /></div>
      <div className="bg-light text-center py-5">
        <Container>
          <h2>Kontaktieren Sie uns</h2>
          <Row className="justify-content-center">
            <Col md={6}>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" name="name" placeholder="Ihr Name" required />
                </Form.Group>

                <Form.Group controlId="formEmail" className="mt-3">
                  <Form.Label>E-Mail</Form.Label>
                  <Form.Control type="email" name="email" placeholder="Ihre E-Mail" required />
                </Form.Group>

                <Form.Group controlId="formMessage" className="mt-3">
                  <Form.Label>Nachricht</Form.Label>
                  <Form.Control as="textarea" rows={4} name="message" placeholder="Ihre Nachricht" required />
                </Form.Group>

                <Button
                  variant="primary"
                  type="submit"
                  className="mt-4"
                  style={{ backgroundColor: 'goldenrod', borderColor: 'goldenrod' }}
                >
                  Nachricht senden
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
      <div><Footer /></div>
    </div>
  );
};

export default Kontakt;
