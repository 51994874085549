import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Page/Home';
import Ziel from './Page/Ziel';  // Neue Komponente für 'Ziel'
import Geschickte from './Page/Geschickte';  // Neue Komponente für 'Geschickte'
import Spenden from './Page/Spenden';  // Neue Komponente für 'Spenden'
import Kontakt from './Page/Kontakt';  // Neue Komponente für 'Kontakte'
import UeberUns from './Page/UeberUns';  // Neue Komponente für 'Über Uns'
import Datenschutz from './Page/Datenschutz';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          {/* Route für Home */}
          <Route exact path="/" element={<Home />} />
          
          {/* Route für Ziel */}
          <Route path="/ziel" element={<Ziel />} />
          
          {/* Route für Geschickte */}
          <Route path="/geschickte" element={<Geschickte />} />
          
          {/* Route für Spenden */}
          <Route path="/spenden" element={<Spenden />} />
          
          {/* Route für Kontakte */}
          <Route path="/kontakt" element={<Kontakt />} />
          
          {/* Route für Über Uns */}
          <Route path="/ueber-uns" element={<UeberUns />} />
          <Route path="/datenschutz" element={<Datenschutz/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
