import React from 'react';
import '../App.css';

const Footer = () => {
  return (
    <div className="container py-3 footer">
      <footer className="bg-light text-center py-4">
        <div className="container">
          <div className="row">
            <div className="col mb-4 mb-sm-0"> {/* Ajoute mb-4 pour espacement en petits écrans */}
              <h5>Folgen Sie uns auf Facebook</h5>
              <a className="black-link" href="https://www.facebook.com/peguyadamn" target="_blank" rel="noopener noreferrer">Facebook</a>
            </div>
            <div className="col mb-4 mb-sm-0"> {/* Ajoute mb-4 pour espacement en petits écrans */}
              <ul className="list-unstyled">
                <li><a className="black-link" href="/datenschutz">Datenschutz</a></li>
              </ul>
            </div>
            <div className="col mb-4 mb-sm-0"> {/* Ajoute mb-4 pour espacement en petits écrans */}
              <h5>Kontakte</h5>
              <ul className="list-unstyled">
                <li>Adresse: Junoweg 3a, 44388 Dortmund</li>
                <li>Telefon: +49 176 32769473</li>
                <li>Email: dasevangeliume.v@gmail.com</li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
