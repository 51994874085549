import React from 'react';
import Header from '../Components/Header';
import Navigation from '../Components/Navigation';
import Footer from '../Components/Footer';
import { Container, Row, Col, Button } from 'react-bootstrap';

const Spenden = () => {
    return (
        <div>
            <div className='header'><Header /></div>
            <div><Navigation /></div>
            
            <div className="bg-light text-center py-5">
                <Container>
                    <h2>Spenden</h2>
                    <p>Vielen Dank für Ihr Interesse, uns zu unterstützen. Falls es sich um eine Spende oder die Zehnten handelt, bitte als Verwendungszweck angeben. Hier finden Sie die Informationen, wie Sie spenden können:</p>
                    
                    <Row className="my-4">
                        <Col md={6} className="text-md-end mb-3 mb-md-0">
                            <h5>Spenden über PayPal</h5>
                            <p>
                                Nutzen Sie diesen Link, um direkt über PayPal zu spenden:<br />
                                <a href="https://paypal.me/rencontrepentecote?country.x=DE&locale.x=de_DE" target="_blank" rel="noopener noreferrer">Spenden über PayPal</a>
                            </p>
                        </Col>
                        <Col md={6} className="text-md-start">
                            <h5>Banküberweisung</h5>
                            <p>
                                Hier sind unsere Bankdaten für Überweisungen:<br />
                                Bankname: komm noch<br />
                                IBAN: kommt noch<br />
                                BIC: kommt noch<br />
                                Verwendungszweck: Spende oder Zehnten
                            </p>
                        </Col>
                    </Row>
                    
                    <p>Für weitere Fragen oder Unterstützung, kontaktieren Sie uns bitte unter <a href="mailto:dasevangeliume.v@gmail.com">dasevangeliume.v@gmail.com</a>.</p>
                </Container>
            </div>
            
            <div><Footer /></div>
        </div>
    );
};

export default Spenden;
