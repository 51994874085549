import React from 'react';
import Header from '../Components/Header';
import Navigation from '../Components/Navigation';
import Footer from '../Components/Footer';
import { Container, Row, Col, Jumbotron, Button } from 'react-bootstrap';

const Ziel = () => {
    return (
        <div>
            <div className='header'><Header/></div>
<div><Navigation/></div>
      <div className="bg-light text-center py-5">
        <Container>
          <h2>Unsere Projekte</h2>
        <p> <strong>Projekt1: "Resiliente Gesellschaft: Achtsamkeit und Stärke im Alltag" </strong></p>
                    <p><strong>Initiator:</strong> Verein Das Evangelium e.V.</p>
                    <p><strong>Projektdauer:</strong> 6 Monate (Startdatum: 15.09.2024)</p>
                    <p><strong>Ort:</strong> Zu Gast bei der Evangelische Christusgemeinde in Dortmund/ Provinzialstraße 410, 44388 Dortmund</p>
                    <p><strong>Zeitraum:</strong> Jeden Sonntag, 12:30 Uhr</p>
            <strong>Projektbeschreibung</strong>
            <p>Stress im Alltag ist ein wachsendes Problem in unserer Gesellschaft und wirkt sich sowohl auf die psychische als auch auf die körperliche Gesundheit vieler Menschen aus.</p>
            <p>Unser Verein Das Evangelium e.V. möchte mit dem Projekt "Resiliente Gesellschaft: Achtsamkeit und Stärke im Alltag" Menschen dabei unterstützen, den negativen Stress frühzeitig zu erkennen und geeignete Gegenmaßnahmen zu ergreifen.</p>
            <p>Die WHO, die Weltgesundheitsorganisation, bezeichnet Stress als eine der größten Gefahren des 21. Jahrhunderts.</p>
            <p>Studien zeigen immer wieder den Zusammenhang zwischen Stress, Krankheit und Schmerzen. So gaben bei der Studie der Techniker Krankenkasse aus dem Jahr 2016 mehr als 20 Prozent der Befragten an, dass Stress sie in der Vergangenheit krank gemacht habe.</p>
            <p>Im Rahmen des Projekts werden die Teilnehmer sensibilisiert für die Faktoren, die Stress in ihrem Alltag verursachen, und lernen, den biochemischen Stress-Prozess besser zu verstehen. Ein zentrales Anliegen des Projekts ist es, Menschen zu befähigen, achtsamer mit ihren eigenen Bedürfnissen umzugehen. Wir stellen ihnen zahlreiche Modelle und Techniken zur Verfügung, um aktiv gegen Stress vorzugehen und ihre Lebensqualität nachhaltig zu verbessern.</p>

            <strong>Ziele des Projekts</strong>
            <ul>
                <li>Früherkennung von Stress: Teilnehmer lernen, Warnsignale von Stress rechtzeitig zu erkennen.</li>
                <li>Strategien zur Stressbewältigung: Gemeinsames Erarbeiten von Techniken zur Reduzierung von Stress.</li>
                <li>Förderung des Austauschs: Eine offene Gesprächsrunde, um Erfahrungen zu teilen und voneinander zu lernen.</li>
                <li>Unterstützung in mehreren Sprachen: Unser Projekt wird in drei Sprachen angeboten – Deutsch, Französisch und Englisch – um eine breite Zielgruppe zu erreichen.</li>
            </ul>

            <strong>Ablauf</strong>
            <ul>
                <li><strong>Sonntag, 12:30 Uhr:</strong> Die wöchentlichen Gesprächsrunden starten. Jede Sitzung wird von unserem Vorsitzenden und Stressmanagement-Fachmann Adam Nyanda geleitet.</li>
                <li><strong>Vielfältige Gesprächsrunden:</strong> Die Teilnehmer haben die Möglichkeit, sich in ihrer bevorzugten Sprache (Deutsch, Französisch oder Englisch) auszutauschen.</li>
                <li><strong>Musiktherapie:</strong> Neben den Gesprächen bieten wir auch eine Musiktherapie an, um die Teilnehmer zusätzlich zu entspannen.</li>
                <li><strong>Einzelgespräche:</strong> Auf Wunsch sind auch Einzelgespräche mit unseren Fachkräften möglich, die auf die individuellen Bedürfnisse der Teilnehmer eingehen.</li>
                <li><strong>Besonderheit:</strong> Neben der Leitung durch unseren Vorsitzenden werden auch externe Gäste, überwiegend Theologen, eingeladen, um als Experten in den Gesprächsrunden zu unterstützen. Sie arbeiten auf Provisionsbasis und bereichern das Projekt mit ihrer fachlichen Kompetenz.</li>
            </ul>

            <strong>Zielgruppe</strong>
            <p>Das Projekt richtet sich an alle Menschen, die in ihrem Alltag unter Stress leiden und lernen möchten, wie sie diesen besser bewältigen können. Da die Runden in drei verschiedenen Sprachen stattfinden, wird ein breites Publikum angesprochen, sowohl internationale als auch lokale Teilnehmer.</p>

            <strong>Finanzierung & Sponsoren</strong>
            <p>Da das Projekt für einen Zeitraum von sechs Monaten angesetzt ist, suchen wir Sponsoren, um die laufenden Kosten zu decken. Diese umfassen:</p>
            <ul>
                <li>Honorare für die externen Gäste (Theologen und Experten),</li>
                <li>Raummiete und Ausstattung,</li>
                <li>Kosten für Materialien zur Musiktherapie,</li>
                <li>Werbekosten zur Bekanntmachung des Projekts.</li>
            </ul>

            <strong>Warum sollten Sie uns unterstützen?</strong>
            <ul>
                <li><strong>Gesellschaftlicher Nutzen:</strong> Sie fördern ein Projekt, das das psychische Wohlbefinden in unserer Gesellschaft stärkt.</li>
                <li><strong>Breite Reichweite:</strong> Durch die Mehrsprachigkeit erreichen wir unterschiedliche Gemeinschaften und bieten einen Raum für den interkulturellen Austausch.</li>
                <li><strong>Positives Engagement:</strong> Ihre Unterstützung trägt dazu bei, Menschen in schwierigen Lebenslagen zur Seite zu stehen und langfristig positive Veränderungen herbeizuführen.</li>
            </ul>      
        </Container>
      </div>
      <div><Footer/></div>
        </div>
    );
};

export default Ziel;